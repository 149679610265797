var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { DefaultButton, Stack, Panel, Label } from '@fluentui/react';
import { RecentNotes } from '../notes/recent-notes';
import { useBoolean } from '@fluentui/react-hooks';
import { Search } from '../notes/search';
import { searchIcon } from '../notes/icons';
import { useLoadTodayDiary } from '../notes/todays-diary';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { LogFuelForm } from '../financials/fuel-log/log-fuel-form';
import { PaymentForm } from '../financials/payments/payment-form';
export var Home = function () {
    var _a = useBoolean(false), addPayment = _a[0], _b = _a[1], showAddPayment = _b.setTrue, hideAddPayment = _b.setFalse;
    var _c = useBoolean(false), addFuelLog = _c[0], _d = _c[1], showAddFuelLog = _d.setTrue, hideAddFuelLog = _d.setFalse;
    var _e = useBoolean(false), searchNotes = _e[0], _f = _e[1], showSearchNotes = _f.setTrue, hideSearchNotes = _f.setFalse;
    var history = useHistory();
    var loadTodayDiary = useLoadTodayDiary();
    return (React.createElement("div", null,
        React.createElement(StyledButtons, null,
            React.createElement(DefaultButton, { onClick: showAddPayment }, "Add Payment"),
            React.createElement(DefaultButton, { onClick: showAddFuelLog }, "Add Fuel Log"),
            React.createElement(DefaultButton, { onClick: loadTodayDiary }, 'Today\'s Diary'),
            React.createElement(Stack, { horizontal: true },
                React.createElement(DefaultButton, { onClick: function () { return history.push('/notes'); } }, "View Notes"),
                React.createElement(DefaultButton, { iconProps: searchIcon, onClick: showSearchNotes }))),
        React.createElement(Label, null, "Recent Notes"),
        React.createElement(RecentNotes, null),
        React.createElement(Panel, { isOpen: addPayment, headerText: "Add Payment", isBlocking: false, onDismiss: hideAddPayment },
            React.createElement(PaymentForm, { onClose: hideAddPayment })),
        React.createElement(Panel, { isOpen: addFuelLog, headerText: "Log Fuel", onDismiss: hideAddFuelLog },
            React.createElement(LogFuelForm, { onClose: hideAddFuelLog })),
        React.createElement(Search, { show: searchNotes, onDismiss: hideSearchNotes })));
};
var SPACING = 8;
var StyledButtons = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  @media (max-width: 600px) {\n    flex-direction: column;\n    \n    > button, div {\n      width: 100%;\n      height: 100px;\n    }\n    \n    > button:last-child,div:last-child {\n      margin-bottom: ", "px;\n    }\n    \n    > div > button {\n      height: 100%;\n    }\n\n    > div > button:first-child {\n      flex: 1;\n    }\n  }\n  \n  @media (min-width: 600px) {\n    flex-direction: row;\n    \n    > button {\n      margin-bottom: ", "px;\n      max-width: 300px;\n    }\n\n    > button,div:not(:last-child) {\n      margin-right: ", "px;\n    }\n  }\n  \n  > button, div {\n    margin-top: ", "px;  \n  }\n"], ["\n  display: flex;\n  @media (max-width: 600px) {\n    flex-direction: column;\n    \n    > button, div {\n      width: 100%;\n      height: 100px;\n    }\n    \n    > button:last-child,div:last-child {\n      margin-bottom: ", "px;\n    }\n    \n    > div > button {\n      height: 100%;\n    }\n\n    > div > button:first-child {\n      flex: 1;\n    }\n  }\n  \n  @media (min-width: 600px) {\n    flex-direction: row;\n    \n    > button {\n      margin-bottom: ", "px;\n      max-width: 300px;\n    }\n\n    > button,div:not(:last-child) {\n      margin-right: ", "px;\n    }\n  }\n  \n  > button, div {\n    margin-top: ", "px;  \n  }\n"])), SPACING, SPACING, SPACING, SPACING);
var templateObject_1;
