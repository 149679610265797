export var currencies = [
    'USD', 'ILS'
];
export var currencySymbols = {
    ils: '₪',
    usd: '$'
};
export var defaultCurrency = 'ILS';
export var currencyRadioOptions = currencies.map(function (currency) { return ({
    key: currency.toUpperCase(),
    text: currency.toUpperCase()
}); });
export var currencyFields = new Map();
currencies.forEach(function (c) { return currencyFields.set(c.toLocaleLowerCase(), c.toLowerCase()); });
var additionalUsdFields = ['marketValue', 'costBasis', 'marketPrice', 'avgCostBasis', 'usdCommission', 'usdBalance', 'totalUsd'];
additionalUsdFields.forEach(function (field) { return currencyFields.set(field, 'usd'); });
var additionalIlsFields = ['ilsBalance', 'totalIls'];
additionalIlsFields.forEach(function (field) { return currencyFields.set(field, 'ils'); });
