var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useState } from 'react';
import * as React from 'react';
import { saveTransferCashToStockAccount } from '../../services/server-api';
import { PrimaryButton, DefaultButton, Stack, TextField } from '@fluentui/react';
import { CurrencyInput } from '../currency-input';
import { StockAccountDropdown } from './stock-account-dropdown';
import { expandISODate, todayAsISODate, collapseISODate } from '../../common/date.utils';
import { CategoryDropdown } from '../payments/category-dropdown';
import { currencyRadioOptions, defaultCurrency } from '../currencies';
import { horizontalChoiceGroup, stackTokens, StyledChoiceGroup } from '../styles';
export var CashStockAccountFundsTransferForm = function (_a) {
    var onClose = _a.onClose;
    var _b = useForm(onClose), dto = _b.dto, updateDto = _b.updateDto, updateAccount = _b.updateAccount, updateCategory = _b.updateCategory, updateCurrency = _b.updateCurrency, submitForm = _b.submitForm;
    return (React.createElement("form", null,
        React.createElement(Stack, { tokens: stackTokens },
            React.createElement(TextField, { label: 'Cash Account Date', type: "date", onChange: updateDto, value: dto.cashAccountDate ? expandISODate(dto.cashAccountDate) : undefined, name: "cashAccountDate" }),
            React.createElement(TextField, { label: 'Stock Account Date', type: "date", onChange: updateDto, value: dto.stockAccountDate ? expandISODate(dto.stockAccountDate) : undefined, name: "stockAccountDate" }),
            React.createElement(StockAccountDropdown, { value: dto.stockAccountId, onChange: updateAccount, label: "Stock Account" }),
            React.createElement(TextField, { label: "Cash Allocation", name: "cashAllocationCode", value: dto.cashAllocationCode, onChange: updateDto }),
            React.createElement(CategoryDropdown, { showLabel: true, label: "Cash Category", value: dto.categoryId, onChange: updateCategory }),
            React.createElement(StyledChoiceGroup, { selectedKey: dto.currency, label: "Currency", styles: horizontalChoiceGroup, onChange: updateCurrency, options: currencyRadioOptions }),
            React.createElement(CurrencyInput, { amount: dto.amount, currency: dto.currency, name: "amount", placeholder: "Cash --> Stock Acct: amount > 0", onChange: updateDto }),
            React.createElement(TextField, { label: "Notes", name: "note", value: dto.note, multiline: true, onChange: updateDto }),
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(PrimaryButton, { onClick: submitForm }, "Save"),
                React.createElement(DefaultButton, { onClick: onClose }, "Cancel")))));
};
function useForm(onClose) {
    var _this = this;
    var _a = useState(initializeDto()), dto = _a[0], setDto = _a[1];
    var updateDto = useCallback(function (_a) {
        var _b;
        var target = _a.target;
        setDto(__assign(__assign({}, dto), (_b = {}, _b[target.name] = target.type == 'date' ? collapseISODate(target.value) : target.value, _b)));
    }, [dto]);
    var updateAccount = useCallback(function (stockAccountId) {
        setDto(__assign(__assign({}, dto), { stockAccountId: stockAccountId }));
    }, [dto]);
    var updateCurrency = useCallback(function (_, _a) {
        var key = _a.key;
        setDto(__assign(__assign({}, dto), { currency: key }));
    }, [dto]);
    var updateCategory = useCallback(function (categoryId) {
        setDto(__assign(__assign({}, dto), { categoryId: categoryId }));
    }, [dto]);
    var submitForm = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveTransferCashToStockAccount(dto)];
                case 1:
                    _a.sent();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); }, [dto, onClose]);
    return { dto: dto, updateDto: updateDto, updateCurrency: updateCurrency, updateCategory: updateCategory, updateAccount: updateAccount, submitForm: submitForm };
}
function initializeDto() {
    return {
        stockAccountId: null,
        currency: defaultCurrency,
        categoryId: null,
        cashAllocationCode: '',
        cashAccountDate: todayAsISODate(),
        stockAccountDate: todayAsISODate(),
        amount: null,
        note: '',
    };
}
